import { lazy } from 'react';

import Loadable from './../components/Loaders/Loadable';
import TopLoader from '../components/Loaders/TopLoader';

const QRPage = Loadable(lazy(() => import('../pages/QRPage')), TopLoader);

// ==============================|| MAIN ROUTING ||============================== //

const DefaultRoutes = [
    {
        path: '',
        element: <QRPage />
    }
]

export default DefaultRoutes;
