export default function themePalette() {
    return {
        primary: {
            light: '#ffefad',
            main: "#FBD849",
            dark: '#FC965C',
            text: '#ffffff'
        },
        background: {
            default: "#1a1a1a",
            paper: "#393939"
        },
    }
}
